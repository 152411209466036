<template>
  <section class="container-fluid my-4">
    <h4 class="my-4">Cadastro de <strong>Grade</strong></h4>

    <form @submit.prevent="handlerSubmit">
      <div class="row">
        <div class="form-group col-md-6">
          <label for="name">Nome</label>
          <input
            type="text"
            class="form-control"
            id="name"
            v-model="item.name"
          />
        </div>

        <div class="form-group col-md-3">
          <label for="obs">Observação</label>
          <input type="text" class="form-control" id="obs" v-model="item.obs" />
        </div>

        <div class="col-md-3">
            <label for="">Curso</label>
            <select
              v-model="item.course_id"
              name="course_id"
              id="course_id"
              class="form-control"
            >
              <option disabled value="" selected>-- Selecione --</option>
              <option
                v-for="(opt, index) in courses"
                :key="index"
                :value="opt.id"
              >
                {{ opt.name }}
              </option>
            </select>
        </div>

      </div>

      <div class="form-group text-center my-2">
        <button type="submit" class="btn btn-success btn-sm mx-2">
          SALVAR
        </button>
        <button type="reset" class="btn btn-secondary btn-sm">LIMPAR</button>
      </div>
    </form>

    <button class="btn btn-sm btn-secondary" @click="$router.go(-1)">
      VOLTAR
    </button>
  </section>
</template>
  
  <script>
import api from "@/services";
import { errorsToString } from "@/helpers";

export default {
  name: "GridRegister",
  data() {
    return {
      item: {
        course_id: "",
      },
      courses: [],
    };
  },
  methods: {
    async handlerSubmit() {
      try {
        const { data } = await api.post(`/grids`, this.item);
        Toast.fire(data.message, "", "success");
        this.$router.push({ name: "grids" });
      } catch (error) {
        Toast.fire(errorsToString(error.response.data.errors), "", "error");
      }
    },
    async getCourses() {
      await api.get(`/courses?page=0&perPage=99999`).then((res) => {
        this.courses = res.data.data;
      });
    },
  },
  mounted() {
    this.getCourses();
  },
};
</script>
  
  <style scoped>
</style>